
import store from "@/store";
import {
  dispatchCheckLoggedIn,
  dispatchResendEmailVerification,
} from "@/store/main/actions";
import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
import { Component, Vue } from "vue-property-decorator";
import UserProfile from "./main/profile/UserProfile.vue";

const routeGuardMain = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);
  if (!readIsLoggedIn(store)) {
    next("/login");
  } else {
    next();
  }
};

@Component({
  components: {
    UserProfile,
  },
})
export default class ProfileIndex extends Vue {
  get userProfile() {
    return readUserProfile(this.$store)!;
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }
  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }
}
